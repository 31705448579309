<template>
	<div class="edit-content">
		<div style="background: #fff; padding: 40px; width: 100%">
			<div class="edit-form">
				<div class="lable">标题</div>
				<div class="content">
					<el-input v-model="study.title"></el-input>
				</div>

				<div class="lable">主图</div>
				<div class="content">
					<el-upload class="avatar-uploader" :action="uploadUrl" :show-file-list="false" :on-success="uploadSuccess">
						<el-image style="width: 200px; height: 200px" fit="contain" v-if="imageUrl" :src="imageUrl" class="avatar" />
						<i v-else class="el-icon-plus avatar-uploader-icon"></i>
					</el-upload>
				</div>

				<div class="lable">内容</div>
				<div class="content">
					<vue-editor useCustomImageHandler @image-added="handleImageAdded" v-model="study.content"></vue-editor>
				</div>

				<div class="lable">浏览量</div>
				<div class="content" style="width: 100px">
					<el-input v-model="study.viewCount"></el-input>
				</div>

				<div style="margin-top: 60px; margin-left: 100px">
					<el-button type="primary" @click="saveArticle">保存</el-button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { studyEdit, studyGetById } from '@/utils/api';
import { VueEditor } from 'vue2-editor';

export default {
	components: {
		VueEditor
	},
	mounted() {
		var id = this.$route.query.id;
		if (id != null) {
			studyGetById(id).then((res) => {
				console.log('studyGetById=====' + JSON.stringify(res));
				this.study = res.data;
				this.imageUrl = this.study.mainImg;
			});
		}
	},
	data() {
		return {
			study: {},
			uploadUrl: axios.defaults.baseURL + '/file/ua/upload',
			imageUrl: ''
		};
	},
	methods: {
		saveArticle() {
			studyEdit(this.study).then((res) => {
				window.open('/#/Dashboard/StudyList', '_self', '', true);
			});
		},
		uploadSuccess(res, file) {
			this.study.mainImg = res.data;
			this.imageUrl = res.data;
		},
		handleImageAdded(file, Editor, cursorLocation, resetUploader) {
			var formData = new FormData();
			formData.append('file', file);

			// 配置 Axios 请求
			axios
				.post(axios.defaults.baseURL + '/file/ua/upload', formData, {
					headers: {
						'Content-Type': 'multipart/form-data' // 不需要显式设置 Content-Type，Axios 会根据 FormData 自动设置
					}
				})
				.then((response) => {
					const url = response.data.data;
					Editor.insertEmbed(cursorLocation, 'image', url);
					resetUploader();
					console.log('File uploaded successfully', response.data);
				})
				.catch((error) => {
					console.error('Error uploading file', error);
				});
		}
	}
};
</script>

<style lang="less" scoped>
.edit-content {
	display: flex;
	justify-content: left;
	width: 100%;
	height: calc(100vh - 80px);
	padding: 15px;
	background-color: #f6f7fa;
}
.edit-form {
	text-align: left;
	.lable {
		width: 80px;
		margin: 10px;
		margin-top: 30px;
	}
	.content {
		width: 60%;
	}

	.avatar-uploader {
		line-height: 0px;
		border: solid 1px grey;
		width: 200px;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
}
</style>
