<template>
	<div class="list-content">
		<div style="text-align: left; padding-left: 10px; padding-bottom: 10px; display: flex">
			<el-button type="primary" @click="startCreate">手动创建</el-button>
			<el-button type="primary" @click="openCreateByAI">AI生成</el-button>
			<el-button type="primary" @click="openCreateByTitle">标题生成</el-button>
		</div>
		<el-table border :data="tableData">
			<el-table-column type="index" label="序号" width="50" header-align="center" align="center"></el-table-column>
			<el-table-column prop="mainImg" label="主图" width="200" header-align="center" align="center">
				<template v-slot="scope">
					<el-image style="width: 100px; height: 100px" :src="scope.row.mainImg" fit="scale-down" class="image-column" />
				</template>
			</el-table-column>
			<el-table-column prop="title" label="标题" width="500" header-align="center" align="center"></el-table-column>
			<el-table-column prop="createTime" label="创建时间" width="200" header-align="center" align="center"></el-table-column>
			<el-table-column label="启用/禁用" width="200" header-align="center" align="center">
				<template v-slot="scope">
					<el-switch
						@change="switchPublish(scope.row.id, scope.row.publishFlag)"
						v-model="scope.row.publishFlag == 1"
						active-color="#13ce66"
						inactive-color="#ff4949"
					></el-switch>
				</template>
			</el-table-column>
			<el-table-column label="操作" header-align="left" align="left">
				<template slot-scope="scope">
					<el-button @click="editItem(scope.row.id)">编辑</el-button>
					<el-button @click="deleteItem(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
			<template slot="empty">
				<div style="text-align: center">
					<el-empty description="没有数据">
						<el-button type="primary" style="width: 200px" @click="startCreate">+ 新建</el-button>
					</el-empty>
				</div>
			</template>
		</el-table>
		<el-pagination
			class="pagination"
			background
			layout="total, sizes, prev, pager, next, jumper"
			:total="total"
			:current-page="currentPage"
			:page-sizes="[5, 10, 20, 30, 40]"
			@size-change="handleSizeChange"
			@current-change="handleCurrentChange"
		></el-pagination>
		<el-dialog title="AI生成" :visible.sync="createByAIVisible" width="30%">
			<div style="text-align: left">
				生成数量:
				<el-input v-model="createNumber"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="createByAIVisible = false">取 消</el-button>
				<el-button type="primary" @click="startCreateByAI">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="标题生成" :visible.sync="createByTitleVisible" width="30%">
			<div style="text-align: left">
				文章标题:
				<el-input v-model="title"></el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="createByTitleVisible = false">取 消</el-button>
				<el-button type="primary" @click="startCreateByTitle">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { studyPage, studyDeleteById, switchStatus, createByAI, createByTitle } from '@/utils/api';

export default {
	components: {},
	mounted() {
		this.loadLastRecord(1);
	},
	data() {
		return {
			tableData: [],
			total: 1,
			currentPage: 1,
			pageSize: 10,
			createByAIVisible: false,
			createByTitleVisible: false,
			createNumber: 3,
			title: ''
		};
	},
	methods: {
		startCreateByAI() {
			const loading = this.$loading({
				lock: true,
				text: '处理中……',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			createByAI(this.createNumber).then((res) => {
				console.log('deleteRecord=====' + JSON.stringify(res));
				this.loadLastRecord(this.currentPage, this.pageSize);
				loading.close();
				this.createByAIVisible = false;
			});
		},
		startCreateByTitle(){
			const loading = this.$loading({
				lock: true,
				text: '处理中……',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			createByTitle({value: this.title}).then((res) => {
				console.log('deleteRecord=====' + JSON.stringify(res));
				this.loadLastRecord(this.currentPage, this.pageSize);
				loading.close();
				this.createByTitleVisible = false;
			});
		},
		openCreateByAI() {
			this.createByAIVisible = true;
		},
		openCreateByTitle() {
			this.createByTitleVisible = true;
		},
		switchPublish(id, publishFlag) {
			console.log('=======id=======' + id);
			switchStatus(id, publishFlag == 1 ? 0 : 1).then((res) => {
				this.loadLastRecord(this.currentPage, this.pageSize);
			});
		},
		startCreate() {
			window.open('/#/Dashboard/StudyEdit', '_self', '', true);
		},
		loadLastRecord(pageNo, pageSize) {
			var params = {
				pageNo: pageNo,
				pageSize: pageSize
			};
			studyPage(params).then((res) => {
				console.log('studyPage=====' + JSON.stringify(res));
				this.recordCount = res.data.total;
				this.tableData = res.data.content;
				this.total = res.data.total;
			});
		},
		handleSizeChange(newSize) {
			this.pageSize = newSize;
			this.loadLastRecord(1, newSize);
		},
		handleCurrentChange(newPage) {
			this.currentPage = newPage;
			this.loadLastRecord(newPage, this.pageSize);
		},
		deleteItem(id) {
			this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				studyDeleteById(id).then((res) => {
					console.log('deleteRecord=====' + JSON.stringify(res));
					this.loadLastRecord(this.currentPage, this.pageSize);
				});
			});
		},
		editItem(id) {
			window.open('/#/Dashboard/StudyEdit?id=' + id, '_self', '', true);
		}
	}
};
</script>

<style lang="less" scoped>
.list-content {
	width: 100%;
	height: calc(100vh - 80px);
	padding: 15px;
	background-color: #f6f7fa;

	.pagination {
		margin-top: 20px;
	}
}
</style>