<template>
	<div class="repair-main">
		<div v-show="!srcImg || srcImg.trim() == ''" class="pre-box">
			<div class="pre-title">高清修复</div>
			<div class="pre-desc">自动填补细节、去除噪点和锐化边缘，使原本模糊不清的图片轻松变清晰，还原真实的高清效果</div>
			<div class="pre-box-upload">
				<el-image src="https://oss.djsmart.vip/bed/a001.png"></el-image>
				<div class="upload-box">
					<el-upload :on-success="uploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl" style="width: 100%; height: 80px">
						<el-button type="primary" style="width: 150px; height: 50px; margin-top: 120px; font-size: 16px">
							<i class="el-icon-upload el-icon--left"></i>
							上传图片
						</el-button>
					</el-upload>
					<div class="prepare-upload-tips">
						<div>点击上传图片到此处</div>
						<div>支持JPG、JPEG、PNG、WEBP格式</div>
					</div>
				</div>
			</div>
		</div>
		<div class="image-box" v-show="srcImg && srcImg.trim() !== ''">
			<div class="left-view">
				<div class="src-view-box">
					<el-image fit="contain" :src="srcImg"></el-image>
					<el-upload class="replace-img" :on-success="uploadSuccess" :show-file-list="false" :multiple="false" :action="uploadUrl">替换图片</el-upload>
					<div class="img-type-desc">原图</div>
				</div>
				<div class="repair-opt-box">
					<el-checkbox :checked="faceRepair">面部修复</el-checkbox>
					<el-button type="primary" @click="startHdRepair" style="margin-left: 20px;">
						开始高清修复
						<i style="color: red; margin-left: 10px" class="el-icon-right"></i>
					</el-button>
				</div>
			</div>
			<div class="right-view">
				<div class="desc-view-box"  v-if="descImg">
					<el-image v-if="descImg" :src="descImg"></el-image>
					<div class="img-type-desc">高清图</div>
				</div>
				<el-button  v-if="descImg" type="danger" @click="downImg">下载</el-button>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';
import { hdRepair } from '@/utils/api';
export default {
	components: {},
	data() {
		return {
			uploadUrl: axios.defaults.baseURL + '/file/ua/upload',
			//srcImg: 'https://oss.djsmart.vip/20250104/20250104112235028526685.jpg',
			srcImg: 'https://oss.djsmart.vip/bed/bed018.jpg',
			descImg: '',
			faceRepair: false
		};
	},
	mounted() {},
	methods: {
		uploadSuccess(res, file) {
			this.srcImg = res.data;
		},
		startHdRepair() {
			const loading = this.$loading({
				lock: true,
				text: '正在处理中…',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			hdRepair({ imgUrl: this.srcImg }).then((res) => {
				this.srcImg = res.data.srcImg;
				this.descImg = res.data.generateImg;
				loading.close();
			});
		},
		downImg(imageUrl, imageName) {
			var imageUrl = this.descImg;
			var imageName = new Date().toISOString().replace(/\D/g, '') + '.png';
			if (!imageUrl) {
				return;
			}
			// 使用fetch API获取图片
			fetch(imageUrl)
				.then((response) => {
					// 确保响应是成功的
					if (!response.ok) {
						throw new Error('Network response was not ok.');
					}
					// 将响应体转换为Blob
					return response.blob();
				})
				.then((blob) => {
					// 创建一个链接元素
					const a = document.createElement('a');
					// 创建一个指向Blob的URL
					a.href = window.URL.createObjectURL(blob);
					// 设置下载属性和文件名
					a.download = imageName || 'downloaded-image';
					// 隐藏链接元素
					a.style.display = 'none';
					// 将链接元素添加到文档中
					document.body.appendChild(a);
					// 模拟点击链接
					a.click();
					// 释放Blob URL
					window.URL.revokeObjectURL(a.href);
					// 从文档中移除链接元素
					document.body.removeChild(a);
				})
				.catch((error) => {
					console.error('There was a problem with the fetch operation:', error);
				});
		}
	}
};
</script>
<style lang="less">
@import '@/views/draw/HdRepair.less';
</style>
